import React from "react";
import { IconContext } from "react-icons";
import {
  SectionContainer,
  SectionLine,
  SectionTitle,
} from "../../globalStyles";
import {
  IconText,
  TechnologiesCard,
  TechnologiesContainer,
  TechnologiesList,
  Technology,
} from "./Technologies.elements";
import technologies from "../../config/technologies";

const Technologies = () => {
  return (
    <TechnologiesContainer id="technologies">
      <SectionContainer>
        <SectionTitle>TECHNOLOGIES</SectionTitle>
        <SectionLine />
      </SectionContainer>
      <TechnologiesList>
        <IconContext.Provider value={{ color: "#FF657C", size: "40px" }}>
          <TechnologiesCard>
            {technologies.frontend.map((technology, index) => (
              <Technology key={index}>
                {technology.img}
                <IconText>{technology.name}</IconText>
              </Technology>
            ))}
          </TechnologiesCard>

          <TechnologiesCard>
            {technologies.backend.map((technology, index) => (
              <Technology key={index}>
                {technology.img}
                <IconText>{technology.name}</IconText>
              </Technology>
            ))}
          </TechnologiesCard>
        </IconContext.Provider>
      </TechnologiesList>
    </TechnologiesContainer>
  );
};

export default Technologies;
