import styled from "styled-components";
import { Container, Button } from "../../globalStyles";
import constants from "../../config/constants";

export const ContactContainer = styled(Container)`
  ${Container};
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1180px) {
    padding: 0 40px;
  }

  form {
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  ::placeholder {
    @media screen and (max-width: 700px) {
      font-size: 6px;
    }
  }
`;

export const Input = styled.input`

    background-color: rgba(0,0,0,0);
    color: ${({ theme }) => theme.color};
    border: 1px solid ${({ theme }) => theme.color};
    border-radius: 5px;
    width: 100%;
    height: 50px;
    padding: 15px;

    &:focus{
        outline: none;
    }

    &::placeholder{
        color: color: ${({ theme }) => theme.color};;
    }
`;

export const Textarea = styled.textarea`

    background-color: rgba(0,0,0,0);
    color: ${({ theme }) => theme.color};
    border: 1px solid ${({ theme }) => theme.color};
    border-radius: 5px;
    width: 100%;
    height: 150px;
    padding: 15px;
    resize: none;

    &:focus{
        outline: none;
    }

    &::placeholder{
        color: color: ${({ theme }) => theme.color};
    }
`;

export const SendButton = styled(Button)`
  ${Button};
  margin: 15px 0;
  width: 100%;
  height: 50px;
`;

export const ContactEmail = styled.a`
  color: ${({ theme }) => theme.color};
  text-decoration: none;
  font-size: 15px;
  font-weight: 200;

  &:hover {
    color: ${constants.colors.primary};
  }
`;
