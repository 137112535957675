import React, { useEffect } from "react";
import { Navbar } from "./components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import { Scrollbars } from "react-custom-scrollbars";
import { ThemeProvider } from "styled-components";
import styled from "styled-components";
import constants from "./config/constants";
import Home from "./pages/Home";
import Project from "./pages/Project";
import ReactGA from "react-ga4";

const lightTheme = {
  background: constants.colors.lightBackground,
  color: constants.colors.darkBackground,
  primaryColor: constants.colors.primary,
  secondaryColor: constants.colors.tertiary,
  image: "./images/background/bg.webp",
};

const darkTheme = {
  background: constants.colors.darkBackground,
  color: constants.colors.tertiary,
  primaryColor: constants.colors.primary,
  secondaryColor: constants.colors.tertiary,
  image: "./images/background/bg-dark.webp",
};

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  background-image: url(${({ theme }) => theme.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
`;

function App() {
  const [darkMode, setDarkMode] = React.useState(false);

  useEffect(() => {
    const preferredTheme = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    setDarkMode(preferredTheme);

    ReactGA.initialize(constants.googleAnalytics.trackingId);
  }, []);

  return (
    <Router>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <AppContainer>
          <Scrollbars style={{ height: "100vh" }}>
            <GlobalStyle />
            <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
            <Switch>
              <Route
                path="/"
                exact
                render={() => {
                  ReactGA.send({
                    hitType: "pageview",
                    page: "/",
                    title: "Home",
                  });
                  return <Home darkMode={darkMode} />;
                }}
              />
              <Route
                exact
                path="/project/:id"
                render={(props) => {
                  ReactGA.send({
                    hitType: "pageview",
                    page: `/project/${props.match.params.id}`,
                    title: "Project",
                  });
                  return <Project {...props} />;
                }}
              />
            </Switch>
          </Scrollbars>
        </AppContainer>
      </ThemeProvider>
    </Router>
  );
}

export default App;
