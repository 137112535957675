import React from "react";
import {
  Contact,
  Education,
  Main,
  Projects,
  Technologies,
} from "../components";

function Home({ darkMode }) {
  return (
    <>
      <Main />
      <Education darkMode={darkMode} />
      <Projects darkMode={darkMode} />
      <Technologies />
      <Contact />
    </>
  );
}

export default Home;
