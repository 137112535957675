import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import {
  Nav,
  NavLink,
  HamburgerIcon,
  NavMenu,
  NavItem,
  SwiperContainer,
} from "./Navbar.elements";
import { DarkModeToggle } from "@anatoliygatt/dark-mode-toggle";
import { useHistory } from "react-router-dom";
import routes from "../../config/routes";

const navItems = routes.slice(0, 5);

const Navbar = ({ darkMode, setDarkMode }) => {
  const history = useHistory();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const handleLinkClick = (link) => {
    history.push(link);
    setTimeout(() => {
      window.location.hash = link;
    }, 300);
  };

  return (
    <>
      <Nav>
        <HamburgerIcon onClick={handleClick}>
          {click ? (
            <FaTimes style={{ color: "#fff" }} />
          ) : (
            <FaBars style={{ color: "#FF647C" }} />
          )}
        </HamburgerIcon>
        <NavMenu onClick={handleClick} click={click}>
          {navItems.map((route, index) => (
            <NavItem key={index}>
              <NavLink to="/" onClick={() => handleLinkClick(route.path)}>
                {route.name}
              </NavLink>
            </NavItem>
          ))}
        </NavMenu>
        <SwiperContainer>
          <DarkModeToggle
            mode={darkMode ? "dark" : "light"}
            onChange={() => {
              setDarkMode(!darkMode);
            }}
            ariaLabel="Dark mode toggle"
          />
        </SwiperContainer>
      </Nav>
    </>
  );
};

export default Navbar;
