import styled from "styled-components";
import { Container, Card, Title, Description } from "../../globalStyles";
import constants from "../../config/constants";

export const DividerContainer = styled(Container)`
  ${Container};
  justify-content: center;
  align-items: center;
`;

export const ProjectsContainer = styled(Container)`
  ${Container};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1180px) {
    padding: 0 40px;
  }
`;

export const ProjectList = styled.div`
  ${Container};
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: space-between;
`;

export const ProjectCard = styled(Card)`
  ${Card};
  background-color: ${({ theme }) => theme.background};
  height: auto;
  transition: all 0.8s ease;

  &:hover {
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.4);
  }
`;

export const ImageSection = styled.div`
  width: 100%;
  height: 200px;
`;

export const ProjectImage = styled.img`
  border-radius: 9px 9px 0 0;
  object-fit: cover;
`;

export const ProjectInformation = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px 20px;
  gap: 15px;
`;

export const ProjectTitle = styled(Title)`
  ${Title};
  color: ${({ theme }) => theme.color};
  font-size: 24px;
  font-weight: 600;
`;

export const ProjectSubheading = styled(Description)`
  ${Description};
  color: ${constants.colors.primary};
  font-weight: 400;
  font-size: 17px;
  text-align: left;
`;

export const ProjectChips = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  @media screen and (max-width: 1180px) {
    justify-content: flex-start;
  }
`;

export const ProjectChip = styled.span`
  background-color: ${constants.colors.primary};
  color: ${({ theme }) => theme.background};
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.8s ease;
`;

export const IconGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;
