import {
  SiAngular,
  SiBootstrap,
  SiDotnet,
  SiJavascript,
  SiNodedotjs,
  SiPhp,
  SiReact,
  SiSpringboot,
  SiTailwindcss,
  SiPostgresql,
} from "react-icons/si";

const technologies = {
  frontend: [
    {
      img: <SiJavascript />,
      name: "JavaScript",
    },
    {
      img: <SiReact />,
      name: "React",
    },
    {
      img: <SiAngular />,
      name: "Angular",
    },
    {
      img: <SiBootstrap />,
      name: "Bootstrap",
    },
    {
      img: <SiTailwindcss />,
      name: "Tailwind",
    },
  ],
  backend: [
    {
      img: <SiDotnet />,
      name: ".NET",
    },
    {
      img: <SiSpringboot />,
      name: "Spring Boot",
    },
    {
      img: <SiNodedotjs />,
      name: "NodeJS",
    },
    {
      img: <SiPhp />,
      name: "PHP",
    },
    {
      img: <SiPostgresql />,
      name: "PostgreSQL",
    },
  ],
};

export default technologies;
