const routes = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Education",
    path: "#education",
  },
  {
    name: "Projects",
    path: "#projects",
  },
  {
    name: "Technologies",
    path: "#technologies",
  },
  {
    name: "Contact",
    path: "#contact",
  },
  {
    name: "Project",
    path: "/project/:id",
  },
];

export default routes;
