import db from "../config";
import {
  collection,
  getDocs,
  orderBy,
  query,
  limit,
  where,
} from "firebase/firestore";

const getDocuments = async (name, filters = {}) => {
  const ref = collection(db, name);
  const constraints = [];

  if (Object.hasOwn(filters, "orderBy")) {
    constraints.push(
      orderBy(filters.orderBy.field, filters.orderBy.direction || "asc")
    );
  }

  if (Object.hasOwn(filters, "limit")) {
    constraints.push(limit(filters.limit));
  }

  if (Object.hasOwn(filters, "where")) {
    filters.where.forEach((filter) => {
      constraints.push(
        where(
          filter.field,
          filter.operator || "==",
          filter.value || filter.comparisonValue
        )
      );
    });
  }

  let queryFunction = query(ref, ...constraints);

  const querySnapshot = await getDocs(queryFunction);

  let result = [];
  querySnapshot.forEach((doc) => {
    result.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return result;
};

export default getDocuments;
