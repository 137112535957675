import monthNames from "../config/months";

const convertDate = (seconds) => {
  var date = new Date(1970, 0, 1);
  date.setSeconds(seconds);
  return monthNames[date.getMonth()] + " " + date.getFullYear();
};

const displayDate = (startDate, endDate) => {
  if (startDate && endDate) {
    return `${convertDate(startDate.seconds)} - ${convertDate(
      endDate.seconds
    )}`;
  } else if (startDate) {
    return `${convertDate(startDate.seconds)} - Present`;
  } else {
    return "";
  }
};

export default displayDate;
