import styled from "styled-components";
import { Container } from "../../globalStyles";

export const MainContainer = styled(Container)`
  justify-content: space-between;
  flex-direction: column;
  gap: 40px;
  ${Container};

  @media screen and (max-width: 1180px) {
    padding: 100px 40px 0 40px;
  }
`;

export const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const ProjectTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ProjectTitle = styled.h1`
  font-size: 45px;
  padding-top: 20px;
  padding-bottom: 5px;
  text-align: center;

  @media screen and (max-width: 1180px) {
    font-size: 30px;
  }
`;

export const ProjectLink = styled.a`
  font-size: 20px;
  padding-bottom: 10px;
  text-align: center;
  color: #ff647c;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #ff647c;
    text-decoration: underline;
  }

  @media screen and (max-width: 1180px) {
    font-size: 16px;
  }
`;

export const ProjectSubheading = styled.h3`
  font-size: 20px;
  font-weight: 200;
  text-align: left;

  @media screen and (max-width: 1180px) {
    font-size: 16px;
  }
`;

export const ProjectDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const ProjectDescriptionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ProjectDescriptionTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  text-align: left;
`;

export const ProjectDescriptionText = styled.p`
  font-size: 17px;
  font-weight: 200;
  text-align: justify;

  @media screen and (max-width: 1180px) {
    font-size: 15px;
  }
`;

export const RecommendedProjects = styled.div`
  display: flex;
  gap: 30px;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const RecommendedProjectsTitle = styled.h3`
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  padding-bottom: 20px;

  @media screen and (max-width: 1180px) {
    font-size: 20px;
  }
`;

export const RecommendedProjectImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
`;

export const ProjectWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  &:hover ${RecommendedProjectImage} {
    opacity: 0.4;
  }
`;

export const ProjectIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  border-radius: 50%;
  background-color: rgba(239, 239, 240, 0.7);
  color: #fff;
  padding: 15px 15px 13px 15px;
  z-index: 3;

  ${ProjectWrapper}:hover & {
    display: block;
  }
`;

export const ProjectText = styled.div`
  position: absolute;
  bottom: 25px;
  left: 25px;
  z-index: 2;
  color: white;
  font-size: 18px;
`;

export const ProjectGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.7) 100%
  );
  z-index: 1;

  @media screen and (max-width: 1180px) {
    bottom: 4px;
    border-radius: 10px;
  }
`;
