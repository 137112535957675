import styled from "styled-components";
import { Container, Button } from "../../globalStyles";

export const MainContainer = styled(Container)`
  justify-content: space-between;
  ${Container};
  gap: 50px;

  .portrait {
    height: 500px;
    width: 100%;
    object-fit: cover;
    border-radius: 25%;
    transform: rotate(-5deg);

    @media screen and (max-width: 1180px) {
      display: none;
    }
  }

  @media screen and (max-width: 1180px) {
    padding: 0 40px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 1180px) {
    margin: 30px auto;
  }
`;

export const Divider = styled.hr`
  border: 1px solid rgba(228, 228, 228, 1);
  width: 100%;
  margin: 20px 0;
`;

export const SocialIcons = styled.div`
  display: flex;
`;

export const Icon = styled.div`
  padding-right: 10px;
`;

export const PreHeader = styled.h4`
  color: #ff647c;
  font-size: 17px;
  font-weight: 600;
  padding-top: 5px;

  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
`;

export const Header = styled.h1`
  font-size: 60px;
  font-weight: 700;

  @media screen and (max-width: 500px) {
    font-size: 40px;
  }
`;

export const AboutText = styled.p`
  font-size: 16px;
  padding-bottom: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 25px;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

export const DownloadButton = styled(Button)`
  ${Button};
  width: 325px;
  cursor: pointer;

  @media screen and (max-width: 1180px) {
    width: 100%;
  }
`;

export const CustomLink = ({ href, src, title = "Link" }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      aria-label={title}
    >
      {src}
    </a>
  );
};
