import React, { useEffect, useState } from "react";
import { FaUniversity } from "react-icons/fa";
import { MdWorkOutline } from "react-icons/md";
import {
  EducationPosition,
  EducationPlace,
  EducationDepartment,
  EducationContainer,
  TimelineElementTitle,
} from "./Education.elements";
import {
  SectionContainer,
  SectionLine,
  SectionTitle,
} from "../../globalStyles";
import "react-vertical-timeline-component/style.min.css";
import displayDate from "../../utils/displayDate";
import getDocuments from "../../firebase/firestore/getDocuments";
import constants from "../../config/constants";
import {
  VerticalTimelineElement,
  VerticalTimeline,
} from "react-vertical-timeline-component";

const filters = {
  orderBy: {
    field: "startDate",
    direction: "desc",
  },
};

const Education = ({ darkMode }) => {
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const educationData = async () => {
      const data = await getDocuments(constants.collections.education, filters);
      if (isMounted) {
        setEducation(data);
      }
    };

    const experienceData = async () => {
      const data = await getDocuments(
        constants.collections.experience,
        filters
      );
      if (isMounted) {
        setExperience(data);
      }
    };

    educationData();
    experienceData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <EducationContainer id="education">
      <SectionContainer>
        <SectionTitle>EDUCATION & EXPERIENCE</SectionTitle>
        <SectionLine />
      </SectionContainer>
      {education.length > 0 && (
        <div>
          <TimelineElementTitle>EDUCATION</TimelineElementTitle>
          <VerticalTimeline
            lineColor={
              darkMode
                ? constants.colors.darkBackground
                : constants.colors.lightBackground
            }
          >
            {education.map((unit) => (
              <VerticalTimelineElement
                key={unit.id}
                className="vertical-timeline-element--work"
                contentStyle={{
                  backgroundColor: darkMode
                    ? constants.colors.darkBackground
                    : constants.colors.lightBackground,
                }}
                contentArrowStyle={{
                  borderRight: `7px solid ${
                    darkMode
                      ? constants.colors.darkBackground
                      : constants.colors.lightBackground
                  }`,
                }}
                content
                date={displayDate(unit.startDate, unit.endDate)}
                iconStyle={{
                  background: constants.colors.primary,
                  color: constants.colors.tertiary,
                }}
                icon={<FaUniversity />}
              >
                <EducationPosition>{unit.position}</EducationPosition>
                <EducationPlace>{unit.company}</EducationPlace>
                <EducationDepartment>{unit.description}</EducationDepartment>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      )}

      {experience.length > 0 && (
        <div>
          <TimelineElementTitle>EXPERIENCE</TimelineElementTitle>
          <VerticalTimeline>
            {experience.map((unit) => (
              <VerticalTimelineElement
                key={unit.id}
                className="vertical-timeline-element--work"
                contentStyle={{
                  backgroundColor: darkMode
                    ? constants.colors.darkBackground
                    : constants.colors.lightBackground,
                }}
                contentArrowStyle={{
                  borderRight: `7px solid ${
                    darkMode
                      ? constants.colors.lightBackground
                      : constants.colors.darkBackground
                  }`,
                }}
                date={displayDate(unit.startDate, unit.endDate)}
                iconStyle={{
                  background: constants.colors.primary,
                  color: constants.colors.tertiary,
                }}
                icon={<MdWorkOutline />}
              >
                <EducationPosition>{unit.position}</EducationPosition>
                <EducationPlace>{unit.company}</EducationPlace>
                <EducationDepartment>{unit.description}</EducationDepartment>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      )}
    </EducationContainer>
  );
};

export default Education;
