import React from "react";
import {
  MainContainer,
  ProjectDescriptionItem,
  ProjectDescriptionSection,
  ProjectDescriptionText,
  ProjectDescriptionTitle,
  ProjectGradient,
  ProjectHeader,
  ProjectIcon,
  ProjectLink,
  ProjectSubheading,
  ProjectText,
  ProjectTitle,
  ProjectTitleContainer,
  ProjectWrapper,
  RecommendedProjectImage,
  RecommendedProjects,
  RecommendedProjectsTitle,
} from "./ProjectInformation.element";
import {
  ProjectChip,
  ProjectChips,
  ProjectImage,
} from "../Projects/Projects.elements";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import formatText from "../../utils/formatText";
import { AiOutlineLink } from "react-icons/ai";
import { Link } from "react-router-dom";

const ProjectInformation = ({ project, similarProjects }) => {
  const history = useHistory();

  const handleLinkClick = () => {
    history.push("/");
    setTimeout(() => {
      window.location.hash = "projects";
    }, 300);
  };

  return (
    <MainContainer>
      <ProjectHeader>
        <ProjectTitleContainer>
          <AiOutlineArrowLeft
            size="30px"
            onClick={handleLinkClick}
            style={{ cursor: "pointer" }}
          />
          <ProjectTitle>{project?.title}</ProjectTitle>
          <ProjectLink href={project?.web}>{project?.web}</ProjectLink>
          <ProjectSubheading>{project?.subheading}</ProjectSubheading>
        </ProjectTitleContainer>
        <ProjectChips>
          {project?.tags.map((tag, index) => (
            <ProjectChip key={index}>{tag}</ProjectChip>
          ))}
        </ProjectChips>
      </ProjectHeader>

      <ProjectImage src={project?.image} alt={project?.title} />

      {project?.description && (
        <ProjectDescriptionSection>
          {["introduction", "design_and_features", "impact_and_results"]
            .concat(
              Object.keys(project?.description).filter(
                (key) =>
                  ![
                    "introduction",
                    "design_and_features",
                    "impact_and_results",
                  ].includes(key)
              )
            )
            .map((key) => (
              <ProjectDescriptionItem key={key}>
                <ProjectDescriptionTitle>
                  {formatText(key)}
                </ProjectDescriptionTitle>
                <ProjectDescriptionText>
                  {project?.description[key]}
                </ProjectDescriptionText>
              </ProjectDescriptionItem>
            ))}
        </ProjectDescriptionSection>
      )}

      {similarProjects && (
        <div>
          <RecommendedProjectsTitle>See also</RecommendedProjectsTitle>
          <RecommendedProjects>
            {similarProjects.map((project, index) => (
              <ProjectWrapper key={index}>
                <RecommendedProjectImage src={project.image} alt="Project" />
                <ProjectGradient />
                <ProjectText>
                  <h4>{project.title}</h4>
                  <p>{project.subheading}</p>
                </ProjectText>
                <ProjectIcon>
                  <Link to={`/project/${project.id}`}>
                    <AiOutlineLink size={30} style={{ color: "#fff" }} />
                  </Link>
                </ProjectIcon>
              </ProjectWrapper>
            ))}
          </RecommendedProjects>
        </div>
      )}
    </MainContainer>
  );
};

export default ProjectInformation;
