import styled from "styled-components";
import { Card, Container } from "../../globalStyles";

export const TechnologiesContainer = styled(Container)`
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1180px) {
    padding: 0 40px;
  }
`;

export const TechnologiesList = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;
`;

export const TechnologiesCard = styled(Card)`
  background-color: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  padding: 40px;
`;

export const Technology = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconText = styled.h3`
  color: ${({ theme }) => theme.color};
  font-size: 28px;
  font-weight: 400;
  padding: 0 30px;
`;
