import emailjs from "emailjs-com";
import React, { useRef } from "react";
import constants from "../../config/constants";
import {
  SectionContainer,
  SectionLine,
  SectionTitle,
} from "../../globalStyles";
import {
  ContactContainer,
  ContactEmail,
  Input,
  SendButton,
  Textarea,
} from "./Contact.elements";

const Contact = () => {
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const result = await emailjs.sendForm(
      constants.emailjs.serviceId,
      constants.emailjs.templateId,
      form.current,
      constants.emailjs.userId
    );

    if (result.status === 200) {
      alert("Message has been sent!");
    } else {
      alert("Something went wrong, please try again!");
    }
  };

  return (
    <ContactContainer id="contact">
      <SectionContainer>
        <SectionTitle>CONTACT</SectionTitle>
        <SectionLine />
      </SectionContainer>

      <form ref={form} onSubmit={sendEmail}>
        <Input placeholder="Name" type="text" size="30" name="name" required />
        <Input
          placeholder="Email"
          type="email"
          name="email"
          style={{ margin: "10px 0" }}
          required
        />
        <Textarea placeholder="Message" name="message" required />
        <p style={{ paddingTop: "10px" }}>
          Or contact via:{" "}
          <ContactEmail href="mailto:fabegic@gmail.com">
            fabegic@gmail.com
          </ContactEmail>
        </p>
        <SendButton type="submit" value="Send">
          Send
        </SendButton>
      </form>
    </ContactContainer>
  );
};

export default Contact;
