import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
 * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    overflow-x: none;
    font-family: "Montserrat", sans-serif;
    
    ::selection{
        background-color: #FF647C;
        color: #fff;
    }
 }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 100px auto;
  display: flex;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    margin: 50px auto;
  }
`;

export const Button = styled.button`
  position: relative;
  overflow: hidden;
  color: ${({ theme }) => theme.primaryColor};
  font-size: 16px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ff647c;
  border-radius: 5px;
  height: 50px;

  &:hover {
    color: #fff;
    background-color: #ff647c;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 70px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const SectionTitle = styled.h2`
  font-size: 28px;

  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
`;

export const SectionLine = styled.hr`
  border: 1px solid rgba(228, 228, 228, 1);
  width: 100%;
  height: 1px;
`;

export const Card = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
`;

export const Title = styled.h2`
  color: #000;
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
`;

export default GlobalStyle;
