const constants = {
  recaptcha: {
    siteKey: process.env.REACT_APP_SITE_KEY,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    senderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  collections: {
    education: "education",
    experience: "experience",
    projects: "projects",
  },
  emailjs: {
    serviceId: process.env.REACT_APP_SERVICE_ID,
    templateId: process.env.REACT_APP_TEMPLATE_ID,
    userId: process.env.REACT_APP_USER_ID,
  },
  colors: {
    primary: "#FF647C",
    secondary: "#000",
    tertiary: "#fff",
    lightBackground: "#fff",
    darkBackground: "#1f1f1f",
  },
  googleAnalytics: {
    trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  },
};

export default constants;
