import React from "react";
import {
  AboutText,
  CustomLink,
  Divider,
  DownloadButton,
  Header,
  MainContainer,
  PreHeader,
  SocialIcons,
  TextContainer,
} from "./Main.elements";
import {
  AiFillTwitterCircle,
  AiFillGithub,
  AiFillLinkedin,
} from "react-icons/ai";
import { IconContext } from "react-icons";
import ReactGA from "react-ga4";

const Main = () => {
  return (
    <>
      <MainContainer id="home">
        <TextContainer>
          <SocialIcons>
            <IconContext.Provider value={{ color: "#FF657C", size: "35px" }}>
              <CustomLink
                src={<AiFillTwitterCircle />}
                href="https://twitter.com/fabegic"
                title="Twitter"
              />
              <CustomLink
                src={<AiFillGithub />}
                href="https://github.com/farisbegic"
                title="Github"
              />
              <CustomLink
                src={<AiFillLinkedin />}
                href="https://www.linkedin.com/in/begicfaris/"
                title="LinkedIn"
              />
            </IconContext.Provider>
          </SocialIcons>
          <PreHeader>HELLO, I AM</PreHeader>
          <Header>FARIS BEGIĆ</Header>
          <Divider />
          <AboutText>
            Software Engineer and startup enthusiast, holding a BSc in Computer
            Science. As the founder of a startup, I merge tech and culture to
            create innovative solutions. My full-stack skills and
            problem-solving mindset drive me to craft elegant applications, and
            I'm dedicated to pushing tech boundaries for a global impact.
          </AboutText>
          <CustomLink
            onClick={() => {
              ReactGA.event({
                category: "Download CV",
                action: "User clicked on the download CV button",
              });
            }}
            href="https://firebasestorage.googleapis.com/v0/b/faris-begic-portfolio.appspot.com/o/personal%2FBegic%CC%81%20Faris%20-%20CV.pdf?alt=media&token=275cefec-d1f3-4c91-9396-54809cb27f2c&_gl=1*1iyhnct*_ga*MTAzNDc2OTY4My4xNjg5NDA2NTE3*_ga_CW55HF8NVT*MTY5NjI0NDkyOC45My4xLjE2OTYyNDc4NDguNTcuMC4w"
            src={<DownloadButton>Download my CV</DownloadButton>}
            title="Download my CV"
          />
        </TextContainer>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/faris-begic-portfolio.appspot.com/o/personal%2Fpicture.webp?alt=media&token=280de22c-1723-4465-95c6-6a1f5c06ba5b&_gl=1*holwrw*_ga*MTAzNDc2OTY4My4xNjg5NDA2NTE3*_ga_CW55HF8NVT*MTY5NjQ5NDk4Mi4xMDIuMS4xNjk2NDk1MDM4LjQuMC4w"
          alt="portrait"
          className="portrait"
        />
      </MainContainer>
    </>
  );
};

export default Main;
