import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProjectInformation from "../components/ProjectInformation/ProjectInformation";
import getDocument from "../firebase/firestore/getDocument";
import getDocuments from "../firebase/firestore/getDocuments";

function Project() {
  const [project, setProject] = useState(null);
  const [similarProjects, setSimilarProjects] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const projectData = async () => {
      const project = await getDocument("projects", id);
      setProject(project.result);

      const similarProjects = await getDocuments("projects", {
        where: [
          {
            field: "tags",
            operator: "array-contains-any",
            value: project.result.tags,
          },
          {
            field: "title",
            operator: "!=",
            comparisonValue: project.result.title,
          },
        ],
        limit: 2,
      });

      setSimilarProjects(similarProjects);
    };
    projectData();
  }, [id]);
  return (
    project && (
      <ProjectInformation project={project} similarProjects={similarProjects} />
    )
  );
}

export default Project;
