import React, { useEffect, useState } from "react";
import {
  SectionContainer,
  SectionLine,
  SectionTitle,
} from "../../globalStyles";
import {
  IconGroup,
  ImageSection,
  ProjectCard,
  ProjectChip,
  ProjectChips,
  ProjectImage,
  ProjectInformation,
  ProjectList,
  ProjectsContainer,
  ProjectSubheading,
  ProjectTitle,
} from "./Projects.elements";
import { HiOutlineExternalLink } from "react-icons/hi";
import constants from "../../config/constants";
import getDocuments from "../../firebase/firestore/getDocuments";
import { CustomLink } from "../Main/Main.elements";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

const filters = {
  orderBy: {
    field: "ordinal",
    direction: "asc",
  },
};

const Projects = ({ darkMode }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const projectData = async () => {
      const data = await getDocuments(constants.collections.projects, filters);
      if (isMounted) {
        setProjects(data);
      }
    };

    projectData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <ProjectsContainer id="projects">
      <SectionContainer>
        <SectionTitle>PROJECTS</SectionTitle>
        <SectionLine />
      </SectionContainer>
      <ProjectList>
        {projects.map(({ id, title, image, subheading, web, tags }) => (
          <ProjectCard key={id}>
            <ImageSection>
              <ProjectImage
                src={image}
                alt={title}
                width="100%"
                height="100%"
              />
            </ImageSection>
            <ProjectInformation>
              <ProjectChips>
                {tags.map((tag, index) => (
                  <ProjectChip key={index}>{tag}</ProjectChip>
                ))}
              </ProjectChips>
              <div>
                <ProjectTitle>{title}</ProjectTitle>
                <ProjectSubheading>{subheading}</ProjectSubheading>
              </div>
              <IconGroup>
                <IconContext.Provider
                  value={{
                    color: darkMode
                      ? constants.colors.lightBackground
                      : constants.colors.darkBackground,
                    size: "25px",
                  }}
                >
                  {web != null && (
                    <CustomLink
                      href={web}
                      src={<HiOutlineExternalLink />}
                      title="Web Page"
                    />
                  )}
                  <Link
                    to={`/project/${id}`}
                    title={`Project ${id}`}
                    area-label={`Project ${id}`}
                  >
                    <AiOutlineArrowRight />
                  </Link>
                </IconContext.Provider>
              </IconGroup>
            </ProjectInformation>
          </ProjectCard>
        ))}
      </ProjectList>
    </ProjectsContainer>
  );
};

export default Projects;
