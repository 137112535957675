const formatText = (input) => {
  // Replace underscores with spaces
  const replaced = input.replace(/_/g, " ");

  // Capitalize the first letter of each word
  const capitalized = replaced.replace(/\b\w/g, (char) => char.toUpperCase());

  return capitalized;
};

export default formatText;
